import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';
import { ShopTable } from '@/store/modules/data_interfaces/ShopTableData';
@Module
export default class ShopTableModule extends VuexModule {
    errors = {};
    // tables: ShopTable[] = [{ table_number: 0, qr_code_url: ''}];
    tables: ShopTable = { table_number: 0, qr_code_url: '' };
    get getShopTableErrors() {
        return this.errors;
    }
    get getShopTables() {
        //get tables of a shop
        return this.tables;
    }
    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_SHOP_TABLES](payload) {
        this.errors = {};
        this.tables = payload;
    }

    // Add Shop Tables
    @Action
    [Actions.Add_SHOP_TABLES](payload) {
        ApiService.setHeader();
        return ApiService.post('/shop/auth/generate_qrcodes', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_SHOP_TABLES, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.error)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error,
                        ]);
                    if (response.data.message)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.message,
                        ]);
                }
            });
    }

    // Get shops tables
    @Action
    [Actions.GET_SHOPS_TABLES](payload) {
        ApiService.setHeader();
        return ApiService.post('/shop/auth/get_shop_tables', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_SHOP_TABLES, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.error)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error,
                        ]);
                    if (response.data.message)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.message,
                        ]);
                }
            });
    }
}
