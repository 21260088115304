import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
// import JwtService from '@/core/services/JwtService';

@Module
export default class OffersModue extends VuexModule {
    errors = {};
    offers = {};
    message = {};
    offer_analytics = {};
    all_offers_analytics = {};
    offer = {};
    get getOfferErrors() {
        return this.errors;
    }

    get getAllOffers() {
        return this.offers;
    }

    get getOfferAnalytics() {
        return this.offer_analytics;
    }

    get getAllOffersAnalytics() {
        return this.all_offers_analytics;
    }
    get getOneOffer() {
        return this.offer;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_OFFER]() {
        this.errors = {};
    }
    @Action
    [Actions.CREATE_NEW_OFFER](payload) {
        ApiService.setHeader();
        return ApiService.post('/shop/offers/auth/create_new_offer', payload)
            .then(() => {
                this.context.commit(Mutations.SET_OFFER);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }

    @Mutation
    [Mutations.UPDATE_OFFER]() {
        this.errors = {};
    }
    @Action
    [Actions.UPDATE_OFFER](payload) {
        ApiService.setHeader();
        return ApiService.post('/shop/offers/auth/update_offer', payload)
            .then(() => {
                this.context.commit(Mutations.UPDATE_OFFER);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }

    @Mutation
    [Mutations.SET_ALL_OFFERS](offers) {
        this.errors = {};
        this.offers = offers;
    }
    @Action
    [Actions.GET_ALL_OFFERS](payload) {
        ApiService.setHeader();
        return ApiService.post('/shop/offers/auth/get_all_offers', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_ALL_OFFERS, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }
    @Mutation
    [Mutations.DELETE_OFFER](data) {
        this.errors = {};
        this.message = data;
    }
    @Action
    [Actions.DELETE_SHOP_OFFER](payload) {
        console.log(payload);
        ApiService.setHeader();
        return ApiService.post('/shop/offers/auth/delete_offer', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.DELETE_OFFER, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }

    @Mutation
    [Mutations.SET_SPECIFIC_OFFER_ANALYTICS](offer_analytics) {
        this.errors = {};
        this.offer_analytics = offer_analytics;
    }
    @Action
    [Actions.GET_SPECIFIC_OFFER_ANALYTICS](payload) {
        ApiService.setHeader();
        return ApiService.post(
            '/shop/offers/auth/get_specific_offer_analytics',
            payload
        )
            .then(({ data }) => {
                this.context.commit(
                    Mutations.SET_SPECIFIC_OFFER_ANALYTICS,
                    data
                );
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }

    @Mutation
    [Mutations.SET_ALL_OFFER_ANALYTICS](all_offers_analytics) {
        this.errors = {};
        this.all_offers_analytics = all_offers_analytics;
    }
    @Action
    [Actions.GET_ALL_OFFER_ANALYTICS](payload) {
        ApiService.setHeader();
        return ApiService.post(
            '/shop/offers/auth/get_all_offers_analytics',
            payload
        )
            .then(({ data }) => {
                this.context.commit(Mutations.SET_ALL_OFFER_ANALYTICS, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }
    @Mutation
    [Mutations.SET_OFFER_BY_ID](offer) {
        this.errors = {};
        this.offer = offer;
    }
    @Action
    [Actions.GET_OFFER_BY_ID](payload) {
        ApiService.setHeader();
        return ApiService.post('/shop/offers/get_offer', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_OFFER_BY_ID, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }
}
