import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
// import JwtService from '@/core/services/JwtService';
import { AxiosRequestConfig } from 'axios';

@Module
export default class OffersModue extends VuexModule {
    errors = {};
    invoices = {};
    get getInvoicesErrors() {
        return this.errors;
    }

    get getInvoices() {
        return this.invoices;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }
    @Mutation
    [Mutations.SET_INVOICES](invoices) {
        this.errors = {};
        this.invoices = invoices;
    }
    @Action
    [Actions.GET_INVOICES](payload) {
        ApiService.setHeader();
        return ApiService.post('/get_invoices', payload)
            .then(({ data }) => {
                // console.log(data);
                this.context.commit(Mutations.SET_INVOICES, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }
    @Action
    [Actions.CREATE_INVOICES](payload) {
        ApiService.setHeader();
        return ApiService.post('/calculate_invoice', payload)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }
    @Action
    [Actions.DOWNLOAD_INVOICE](payload: {
        data: any;
        config: AxiosRequestConfig;
    }) {
        ApiService.setHeader();

        return ApiService.post('/download_invoice', payload.data, {
            ...payload.config,
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'invoice.pdf');
                document.body.appendChild(link);
                link.click();
            })
            .catch(({ response }) => {
                console.log('catch: ' + JSON.stringify(response));
                if (response)
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
            });
    }
}
