const our_date_format = {
    // ISO 8601
    just_date: 'YYYY-MM-DD',
    date_with_time: 'YYYY-MM-DD HH:mm',
    time_precise: 'HH:mm:ss:SSS',
    just_time: 'HH:mm',
};
const combo_types = {
    radio_button: 'radio_button',
    check_box: 'check_box',
    multi_select: 'multi_select',
    pre_select: 'pre_select',
};
const category_operations = {
    add_category: 'Add Category',
    edit_category: 'Edit Category',
};

const menu_operations = {
    add_menu: 'Add Menu',
    edit_menu: 'Edit Menu',
};

const combo_operations = {
    add_combo: 'Add Combo',
    edit_combo: 'Edit Combo',
};
const shop_user_operations = {
    add_user: 'Add Shop User',
    edit_user: 'Edit Shop User',
};
const shop_table_operations = {
    add_table: 'Add Table',
};

const actions = {
    ADD: 'add',
    DELETE: 'delete',
    UPDATE: 'update',
};
const roles_shops = {
    admin: 'admin_shop',
    manager: 'manager_shop',
    counter_person: 'counter_person',
};
// we have a function in Opening hours to genereate time dynamically
const twelve_hours_time = [
    {
        time: '12:00AM',
        disable: false,
    },
    {
        time: '01:00AM',
        disable: false,
    },
    {
        time: '02:00AM',
        disable: false,
    },
    {
        time: '03:00AM',
        disable: false,
    },
    {
        time: '04:00AM',
        disable: false,
    },
    {
        time: '05:00AM',
        disable: false,
    },
    {
        time: '06:00AM',
        disable: false,
    },
    {
        time: '07:00AM',
        disable: false,
    },
    {
        time: '08:00AM',
        disable: false,
    },
    {
        time: '09:00AM',
        disable: false,
    },
    {
        time: '10:00AM',
        disable: false,
    },
    {
        time: '11:00AM',
        disable: false,
    },
    {
        time: '12:00PM',
        disable: false,
    },
    {
        time: '01:00PM',
        disable: false,
    },
    {
        time: '02:00PM',
        disable: false,
    },
    {
        time: '03:00PM',
        disable: false,
    },
    {
        time: '04:00PM',
        disable: false,
    },
    {
        time: '05:00PM',
        disable: false,
    },
    {
        time: '06:00PM',
        disable: false,
    },
    {
        time: '07:00PM',
        disable: false,
    },
    {
        time: '08:00PM',
        disable: false,
    },
    {
        time: '09:00PM',
        disable: false,
    },
    {
        time: '10:00PM',
        disable: false,
    },
    {
        time: '11:00PM',
        disable: false,
    },
];

const week_days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

// Discount things
const discount_types = {
    percentage: 'Percentage',
    fixed: 'Fixed amount',
    free_shipping: 'Free shipping',
    buy_x_get_y: 'Buy X get Y',
    //   points: "X points",
};
const product_x = { category: 'Category', menu_items: 'Menu items' };
const product_y = { category: 'Category', menu_items: 'Menu items' };
const product_y_discount_types = {
    percentage: 'Percentage',
    fixed: 'Fixed amount',
    free: 'Free',
};
// For free shipping
const minimum_requirements = {
    none: 'None',
    quantity: 'Minimum Quantity of items',
    amount: 'Minimum purchase amount (£)',
};
const applies_to_options = {
    entire: 'Entire order',
    category: 'Specific categories',
    menu_items: 'Specific menu items',
};
const menus = { menu: 'Menu', category: 'Category' };
function generate_discount_code(length = 14) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}
const discount_status = {
    active: 'Active',
    scheduled: 'Scheduled',
    expired: 'Expired',
};
// Discount things

const order_status = {
    order_placed: 'order_placed', //new order
    order_accepted: 'order_accepted', //pending
    order_rejected: 'order_rejected', //rejected
    order_prepared: 'order_prepared', //ready
    order_out_for_delivery: 'order_out_for_delivery', //ready
    order_delivered: 'order_delivered', //compeleted
    order_collected: 'order_collected', //compeleted
    order_cancelled: 'order_cancelled_refunded', //cancelled
};
const payment_status = {
    paid: 'paid',
    unpaid: 'unpaid',
    refunded: 'refunded',
    blacklist: 'blacklist',
};
const order_type = { delivery: 'delivery', collection: 'collection' };
const live_order_tabs = {
    search_order: 'Search',
    new_order: 'New Order',
    pending_order: 'Pending',
    ready_order: 'Ready',
    completed_order: 'Completed',
    rejected_order: 'Rejected',
};
const order_tabs = {
    order_history: 'Order History',
    order_summary: 'Order Summary',
};
const delivery_charge_type = {
    area: 'area',
    miles: 'miles',
};
const refundReasons = [
    "I don't want to provide any reason",
    'Delivery too late',
    'User changed his mind',
    'Duplicate order',
    'Missing items or quality not adequate',
    'Description was not accurate',
    'Others',
];
const refundTypes = {
    fully_refund: 'fully_refund',
    partial_refund: 'partial_refund',
};
const refundStatus = {
    pending: 'pending',
    inprogress: 'inprogress',
    completed: 'completed',
    rejected: 'rejected',
};
const paymentMethods = {
    cash: 'cash',
    card: 'card',
    google_pay: 'google pay',
    apple_pay: 'apple pay',
};

const allergies = [
    'Celery',
    'Cereals',
    'Crustaceans ',
    'Eggs',
    'Fish',
    'Lupin',
    'Milk',
    'Molluscs',
    'Mustard',
    'Peanuts',
    'Sesame',
    'Soybeans',
    'Sulphites',
    'Nuts',
];
const charge_scopes = ['delivery', 'collection', 'both'];
module.exports = {
    our_date_format,
    category_operations,
    menu_operations,
    combo_operations,
    shop_user_operations,
    twelve_hours_time,
    week_days,
    actions,
    discount_types,
    product_x,
    product_y,
    product_y_discount_types,
    minimum_requirements,
    applies_to_options,
    generate_discount_code,
    menus,
    discount_status,
    order_status,
    payment_status,
    order_type,
    live_order_tabs,
    order_tabs,
    roles_shops,
    delivery_charge_type,
    refundReasons,
    refundTypes,
    refundStatus,
    paymentMethods,
    allergies,
    combo_types,
    charge_scopes,
    shop_table_operations,
};
